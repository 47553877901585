import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Box, Grid, Button } from '@mui/material';
import Panel from '../../components/Panel';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import AssetCoinCell from '../../components/asset/AssetCoinCell';
import Separator from '../../components/Separator';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { AccountAPI } from '../../apis/AccountAPI';
import { UserContext } from '../../UserContext';
import { useEffectOnce } from '../../utils/UseEffectOnce';
import {
  PriceNumberFormat,
  priceNumberRoundedToInt,
} from '../../utils/CoinNumberFormat';
import Dialog from '../../components/Dialog';

const RoundButton = ({ children, startIcon, onClick }) => {
  return (
    <Button
      variant="contained"
      disableElevation
      fullWidth
      sx={{ height: '48px' }}
      style={{
        borderRadius: '24px',
        backgroundColor: '#D9D9D9',
        fontSize: '15px',
      }}
      startIcon={startIcon}
      onClick={onClick}>
      {children}
    </Button>
  );
};

const AssetButton = styled(Button)(({ theme }) => ({
  color: '#070707',
  fontSize: '14px',
  fontWeight: 'medium',
}));

export default function AssetPage() {
  const { user, setLoading, setTabIndex } = useContext(UserContext);
  const [accounts, setAccounts] = useState([]);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  useEffectOnce(() => {
    async function fetchAccountsData() {
      if (user) {
        setLoading(true);
        const _accounts = await AccountAPI.getAccounts();
        setAccounts(_accounts);
        setLoading(false);
      }
    }
    fetchAccountsData();
    setTabIndex(1);
  }, [user]);

  const assetItems = [];
  let digitalAssetSum = 0;
  let ntdAssetSum = 0;
  for (let i = 0; i < accounts.length; i++) {
    if (accounts[i].currencyId === 'TWD') {
      ntdAssetSum = accounts[i].availableBalance;
    } else {
      digitalAssetSum += priceNumberRoundedToInt(accounts[i].twdValue);
      assetItems.push(
        <AssetCoinCell
          key={accounts[i].id}
          {...accounts[i]}
          isLastItem={i !== accounts.length - 1}
          onClick={() => handleCurrency(accounts[i].currencyId)}
        />,
      );
    }
  }

  const totalSum = digitalAssetSum + ntdAssetSum;

  const handleAssetHistory = () => {
    navigate('/assetHistory/ALL');
  };

  const handleDeposit = () => {
    setShowDialog(true);
  };

  const handleWithdraw = () => {
    if (user?.bankAccount?.status === 'succeed') {
      navigate('/withdraw/bank', { state: { accounts } });
    } else if (
      user?.bankAccount?.status === 'failed' ||
      user?.bankAccount?.status === 'verifing'
    ) {
      navigate('/bankStatus', {
        state: { from: '/asset' },
      });
    } else {
      navigate('/bankAccount', { state: { from: '/asset' } });
    }
  };

  const handleCurrency = (currencyId) => {
    navigate(`/market/${currencyId}-TWD`);
  };

  const assetSummary = () => {
    return (
      <>
        <Panel>
          <Grid pt="16px" container spacing="5vw">
            <Grid item xs={6}>
              <Box
                display="flex"
                // alignItems='center'
                flexDirection="column"
                marginLeft="5vw">
                <SubTitleLabel fontSize="15px" fontWeight="regular">
                  帳戶資產總值(TWD)
                </SubTitleLabel>
                <TitleLabel fontSize="24px" fontWeight="bold">
                  <PriceNumberFormat value={totalSum} prefix="$" />
                </TitleLabel>
              </Box>
            </Grid>

            <Grid item xs={6}></Grid>
          </Grid>

          <Box sx={{ height: '16px' }}></Box>

          <Grid pb="16px" container spacing="5vw">
            <Grid item xs={6}>
              <Box
                display="flex"
                // alignItems='center'
                flexDirection="column"
                marginLeft="5vw">
                <SubTitleLabel fontSize="15px" fontWeight="regular">
                  加密資產(TWD)
                </SubTitleLabel>
                <TitleLabel fontSize="20px" fontWeight="bold">
                  <PriceNumberFormat value={digitalAssetSum} prefix="$" />
                </TitleLabel>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="end"
                flexDirection="column"
                marginRight="5vw">
                <SubTitleLabel fontSize="15px" fontWeight="regular">
                  新台幣資產(TWD)
                </SubTitleLabel>
                <TitleLabel fontSize="20px" fontWeight="bold">
                  <PriceNumberFormat value={ntdAssetSum} prefix="$" />
                </TitleLabel>
              </Box>
            </Grid>
          </Grid>
        </Panel>

        <Box sx={{ height: '16px' }}></Box>
      </>
    );
  };

  const depositWithdrawButtons = () => {
    return (
      <Box marginTop="12px">
        <Grid container spacing={2} columns={16}>
          <Grid item xs={8}>
            <RoundButton startIcon={<DownloadIcon />} onClick={handleDeposit}>
              台幣儲值
            </RoundButton>
          </Grid>
          <Grid item xs={8}>
            <RoundButton startIcon={<UploadIcon />} onClick={handleWithdraw}>
              台幣提領
            </RoundButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const assetList = () => {
    return (
      <Box marginTop="16px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TitleLabel fontSize="18px" fontWeight="medium">
            我的持幣資產(TWD)
          </TitleLabel>

          <AssetButton
            startIcon={<TextSnippetIcon />}
            onClick={handleAssetHistory}>
            交易紀錄
          </AssetButton>
        </Box>

        <Box sx={{ height: '8px' }}></Box>

        <Panel>
          {/* 標題 */}
          <Box margin="16px">
            <Grid container spacing="2px">
              {/* 幣種 */}
              <Grid item xs={4}>
                <SubTitleLabel align="center">幣種</SubTitleLabel>
              </Grid>

              {/* 數量 */}
              <Grid item xs={4}>
                <SubTitleLabel align="center">數量</SubTitleLabel>
              </Grid>

              {/* 台幣價值 */}
              <Grid item xs={4}>
                <SubTitleLabel align="center">台幣價值</SubTitleLabel>
              </Grid>
            </Grid>
          </Box>

          <Separator />
          {assetItems}
        </Panel>
      </Box>
    );
  };

  return (
    <>
      <Box padding="16px">
        {assetSummary() /* 資產總覽 */}
        {depositWithdrawButtons() /* 台幣儲值/提領 */}
        {assetList() /* 持幣資產 */}
      </Box>
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="台幣儲值"
        message="請選擇想要的台幣儲值方式"
        actionLabel="台灣大哥大門市"
        secondaryActionLabel="銀行轉帳"
        actionHandler={() => {
          if (user?.bankAccount?.status === 'succeed') {
            navigate('/deposit/store');
          } else if (
            user?.bankAccount?.status === 'failed' ||
            user?.bankAccount?.status === 'verifing'
          ) {
            navigate('/bankStatus', {
              state: { from: '/asset' },
            });
          } else {
            navigate('/bankAccount', { state: { from: '/asset' } });
          }
        }}
        secondaryActionHandler={() => {
          if (user?.bankAccount?.status === 'succeed') {
            navigate('/deposit/bank');
          } else if (
            user?.bankAccount?.status === 'failed' ||
            user?.bankAccount?.status === 'verifing'
          ) {
            navigate('/bankStatus', {
              state: { from: '/asset' },
            });
          } else {
            navigate('/bankAccount', { state: { from: '/asset' } });
          }
        }}
      />
    </>
  );
}
