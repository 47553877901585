import React from 'react';
import { Box } from '@mui/material';
import Panel from '../Panel';

import {
  SuccessStatusBox,
  PendingStatusBox,
  CancelStatusBox,
} from '../StatusBox';
import {
  Label,
  SubTitleLabel,
  SuccessLabel,
  PendingLabel,
  CancelLabel,
} from '../Label';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';

const StatusBoxDict = {
  done: (
    <SuccessStatusBox>
      <SuccessLabel>已完成</SuccessLabel>
    </SuccessStatusBox>
  ),
  succeed: (
    <SuccessStatusBox>
      <SuccessLabel>提領成功</SuccessLabel>
    </SuccessStatusBox>
  ),
  open: (
    <PendingStatusBox>
      <PendingLabel>處理中</PendingLabel>
    </PendingStatusBox>
  ),
  processing: (
    <PendingStatusBox>
      <PendingLabel>處理中</PendingLabel>
    </PendingStatusBox>
  ),
  rejected: (
    <CancelStatusBox>
      <CancelLabel>提領失敗</CancelLabel>
    </CancelStatusBox>
  ),
  fbo_confirmed: (
    <PendingStatusBox>
      <PendingLabel>處理中</PendingLabel>
    </PendingStatusBox>
  ),
  txt_done: (
    <PendingStatusBox>
      <PendingLabel>處理中</PendingLabel>
    </PendingStatusBox>
  ),
  refunding: (
    <CancelStatusBox>
      <CancelLabel>處理中</CancelLabel>
    </CancelStatusBox>
  ),
};

const TypeDict = {
  withdraw: '銀行轉帳',
  bank: '銀行轉帳',
  store: '門市儲值',
  order: '訂單儲值',
};

export default function FundRecordCell({
  onClick,
  type,
  amount,
  time,
  status,
}) {
  return (
    <Panel gutterBottom>
      <Box
        alignItems="center"
        justifyContent="space-between"
        height="72px"
        display="flex"
        padding="16px"
        onClick={onClick}>
        {/* 出入金方式 */}
        <Box marginLeft="0px">
          <Label fontSize="15px" fontWeight="bold">
            {TypeDict[type]}
          </Label>

          <SubTitleLabel fontSize="11px" fontWeight="regular" minWidth="95px">
            {time}
          </SubTitleLabel>
        </Box>

        {/* 出入金金額 */}
        <Box minWidth="120px">
          <Label
            align="right"
            fontSize="15px"
            fontWeight="medium"
            color="#343A40">
            <CoinNumberFormat value={amount} suffix=" TWD" />
          </Label>
        </Box>

        {/* 出入金狀態 */}
        {StatusBoxDict[status]}
      </Box>
    </Panel>
  );
}
