import React, { useState, useContext } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { Box, Button, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';
import { TitleLabel, SubTitleLabel } from '../Label';
import { ProductAPI } from '../../apis/ProductAPI';
import Panel from '../Panel';
import Icon from '../Icon';
import { useEffectOnce } from '../../utils/UseEffectOnce';
import StyledButton from '../../components/StyledButton';
import BottomPanel from '../BottomPanel';
import { UserContext } from '../../UserContext';
import CircularProgress from '@mui/material/CircularProgress';

const OrderStatusTextDict = {
  success: '交易已成功',
  pending: '交易處理中',
  cancel: '交易已取消',
};

const OrderStatusImageDict = {
  success: 'img_success.png',
  pending: 'img_processing.png',
  cancel: 'img_fail.png',
};

const getOrderStatus = (order) => {
  let state = 'pending';
  if (order.status === 'done') {
    if (order.doneReason === 'succeed') {
      state = 'success';
    } else if (order.doneReason === 'rejected') {
      state = 'cancel';
    } else if (order.doneReason === 'cancel') {
      state = 'cancel';
    }
  }
  return state;
};

const CopyButton = styled(Button)(({ theme }) => ({
  color: '#646464',
  borderColor: '#909090',
  fontSize: '15px',
  padding: '2px',
  marginLeft: '5px',
  '&:hover, &:focus, &:active': {
    borderColor: '#909090',
  },
}));

const getOrderRejectReason = (order) => {
  let rejectReason = '';
  if (order.doneReason === 'cancel') {
    rejectReason = '主動取消';
  } else if (order.doneReason === 'rejected') {
    if (order.rejectReason === 'unpaid') {
      rejectReason = '逾期付款';
    } else if (order.rejectReason === 'coinbase') {
      rejectReason = '幣價波動';
    }
  }
  return rejectReason;
};

export default function OrderInfo({ order }) {
  const navigate = useNavigate();
  const [side, setSide] = useState('買入');
  const [product, setProduct] = useState(null);
  const [quantityTitle, setQuantityTitle] = useState('購買數量');
  const [priceTitle, setPriceTitle] = useState('購買單價');
  const [totalTitle, setTotalTitle] = useState('交易總額');
  const status = getOrderStatus(order);
  const [openCopy, setOpenCopy] = useState(false);
  const rejectReason = getOrderRejectReason(order);
  const { setTabIndex } = useContext(UserContext);

  useEffectOnce(() => {
    async function fetchProductData() {
      const product = await ProductAPI.getProduct(order.productId);
      setProduct(product);
    }
    fetchProductData();

    if (order.side === 'sell') {
      setSide('賣出');
      setQuantityTitle('賣出數量');
      setPriceTitle('賣出單價');
      setTotalTitle('總金額');
    }
  }, []);

  const toinvoicePage = () => {
    navigate('/invoiceInfo', { state: { order, from: 'tradeDone' } });
  };

  const handleClickCopy = () => {
    navigator.clipboard.writeText(order.id);
    setOpenCopy(true);
  };

  const handleCloseCopy = () => {
    setOpenCopy(false);
  };

  const handleAsset = () => {
    setTabIndex(1);
    navigate('/asset');
  };

  const abbrOrderId = () => {
    return order.id.slice(0, 10) + '...';
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disableElevation
          onClick={handleAsset}>
          確認我的資產
        </StyledButton>
      </BottomPanel>
    );
  };

  return (
    <>
      <Box marginTop="23px" marginLeft="16px" marginRight="16px">
        <Panel sx={{ borderRadius: '12px' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="40px">
            <Icon src={OrderStatusImageDict[status]} width={122} height={122} />
          </Box>
          <Box
            marginTop="26px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <TitleLabel fontSize="24px" fontWeight="bold" color="#717171">
              {OrderStatusTextDict[status]}
            </TitleLabel>
            <SubTitleLabel fontWeight="bold" color="#717171" marginTop="10px">
              {rejectReason}
            </SubTitleLabel>
          </Box>
          <Box
            marginTop="42px"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Icon
              src={`/images/${product?.baseCurrencyId}.png`}
              width={32}
              height={32}
            />
            <Box marginLeft="8px" marginTop="2px">
              <SubTitleLabel fontSize="16px">
                {product?.baseCurrencyId}
              </SubTitleLabel>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              padding: '16px',
            }}>
            <Divider />

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="23px">
              <TitleLabel fontSize="15px">訂單號碼</TitleLabel>
              <Box display="flex" alignItems="center">
                <TitleLabel fontSize="15px">{abbrOrderId()}</TitleLabel>
                <CopyButton variant="outlined" onClick={handleClickCopy}>
                  複製
                </CopyButton>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="9px">
              <TitleLabel fontSize="15px">日期</TitleLabel>
              <TitleLabel fontSize="15px">
                {moment.unix(order.createdTime).format('YYYY/MM/DD HH:mm')}
              </TitleLabel>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="9px">
              <TitleLabel fontSize="15px">類型</TitleLabel>
              <TitleLabel fontSize="15px" fontWeight="bold">
                {side}
              </TitleLabel>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="9px">
              <TitleLabel fontSize="15px">{quantityTitle}</TitleLabel>
              <TitleLabel fontSize="15px">
                <NumberFormat
                  value={order.size}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={order.size < 100 ? 9 : 2}
                />
              </TitleLabel>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="9px"
              marginBottom="18px">
              <TitleLabel fontSize="15px">{priceTitle}</TitleLabel>
              <TitleLabel fontSize="15px">
                NT$&nbsp;
                <NumberFormat
                  value={order.totalPrice}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={order.totalPrice < 100 ? 2 : 0}
                  fixedDecimalScale={true}
                />
              </TitleLabel>
            </Box>
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="16px">
              <TitleLabel fontSize="15px">{totalTitle}</TitleLabel>
              <TitleLabel fontSize="15px" fontWeight="bold">
                NT$&nbsp;
                <NumberFormat
                  value={order.fund}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={order.fund < 100 ? 2 : 0}
                  fixedDecimalScale={true}
                />
              </TitleLabel>
            </Box>
          </Box>
        </Panel>
      </Box>
      {order.doneReason === 'succeed' && order.invoiceInfo !== null ? (
        <Box
          marginTop="23px"
          marginLeft="16px"
          marginRight="16px"
          marginBottom="90px">
          {order.invoiceInfo.invoiceNo ? (
            <StyledButton
              variant="contained"
              disableElevation
              fullWidth
              sx={{ height: '48px' }}
              onClick={toinvoicePage}
              style={{
                borderRadius: '8px',
                fontSize: '15px',
              }}>
              <TitleLabel fontSize="15px" color="#FFFFFF">
                發票
              </TitleLabel>
            </StyledButton>
          ) : (
            <StyledButton
              variant="contained"
              disableElevation
              fullWidth
              disabled={true}
              sx={{ height: '48px' }}
              style={{
                borderRadius: '8px',
                fontSize: '15px',
              }}>
              <TitleLabel fontSize="15px" color="#FFFFFF">
                發票處理中
              </TitleLabel>
            </StyledButton>
          )}
        </Box>
      ) : (
        <Box marginBottom="90px">{bottomPanel()}</Box>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openCopy}
        autoHideDuration={5000}
        onClose={handleCloseCopy}
        message="訂單號碼已複製"
        key={'bottomCenter'}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: 'center',
          },
        }}
      />
    </>
  );
}
