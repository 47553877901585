import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Divider } from '@mui/material';
import moment from 'moment/moment';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import Panel from '../../components/Panel';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

const sxContainer = {
  pt: `${navigationBarHeight}px`,
};

export default function InvoiceInfoPage() {
  const { state } = useLocation();
  const order = state?.order;
  const from = state?.from;
  const navigate = useNavigate();

  const handleBack = () => {
    if (from === 'tradeDone') {
      navigate('/tradeDone', { state: { order } });
    } else {
      navigate(-1);
    }
  };

  const formatDate = () => {
    const year = Number(moment.unix(order.createdTime).format('YYYY')) - 1911;
    const month = Number(moment.unix(order.createdTime).format('MM'));
    let monthToMonth = null;
    if (month % 2 === 0) {
      monthToMonth = month - 1 + '-' + month;
    } else {
      monthToMonth = month + '-' + (month + 1);
    }
    return year + '年' + monthToMonth + '月';
  };

  const invoiceType = () => {
    switch (order.invoiceInfo.carrierType) {
      case 'A':
        return '會員載具';
      case 'B':
        return '手機條碼';
      case 'C':
        return '自然人憑證';
      case 'P':
        return '紙本';
      default:
        return '捐贈發票';
    }
  };

  const invoiceDetail = () => {
    if (order.carrierId) {
      return `載具號碼：${order.carrierId}`;
    }
    if (order.npoName) {
      return `捐贈單位：${order.npoName}`;
    }
    if (order.invoiceInfo.address) {
      return `發票寄送地址：${order.invoiceInfo.address}`;
    }
  };

  return (
    <Box sx={sxContainer}>
      <NavigationBar
        title={order.invoiceInfo.invoiceNo}
        leftButtonHandler={handleBack}
      />
      <Box marginTop="23px" marginLeft="16px" marginRight="16px">
        <Panel sx={{ borderRadius: '12px' }}>
          <Box marginTop="16px" marginLeft="16px">
            <TitleLabel fontSize="21px" fontWeight="bold" color="#717171">
              富昇數位股份有限公司
            </TitleLabel>
          </Box>
          <Box marginTop="8px" marginLeft="16px" marginRight="16px">
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center">
              <Grid item xs={6}>
                <TitleLabel fontSize="17px" fontWeight="bold" color="#6C757D">
                  {formatDate()}
                </TitleLabel>
              </Grid>
              <Grid item xs={6}>
                <TitleLabel fontSize="17px" fontWeight="bold" color="#6C757D">
                  {order.invoiceInfo.invoiceNo}
                </TitleLabel>
              </Grid>
            </Grid>
          </Box>
          <Box
            marginTop="8px"
            marginLeft="16px"
            marginRight="16px"
            marginBottom="16px">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Box display="flex">
                  <CalendarTodayOutlinedIcon
                    style={{ fontSize: 16 }}
                    sx={{ marginRight: '7px' }}
                  />
                  <TitleLabel fontSize="13px" color="#6C757D">
                    {order.invoiceInfo.createdTime}
                  </TitleLabel>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex">
                  <ShoppingBagOutlinedIcon
                    style={{ fontSize: 16 }}
                    sx={{ marginRight: '7px' }}
                  />
                  <TitleLabel fontSize="13px" color="#6C757D">
                    賣方統編 90115398
                  </TitleLabel>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <ReceiptOutlinedIcon
                    style={{ fontSize: 16 }}
                    sx={{ marginRight: '7px' }}
                  />
                  <TitleLabel
                    fontSize="13px"
                    color="#6C757D"
                    style={{ wordBreak: 'break-all' }}>
                    發票儲存方式：{invoiceType()}
                  </TitleLabel>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <ReceiptLongOutlinedIcon
                    style={{ fontSize: 16 }}
                    sx={{ marginRight: '7px' }}
                  />
                  <TitleLabel
                    fontSize="13px"
                    color="#6C757D"
                    style={{ wordBreak: 'break-all' }}>
                    {invoiceDetail()}
                  </TitleLabel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Box>
      <Box marginTop="24px" marginLeft="16px">
        <TitleLabel fontSize="15px" color="#000000">
          明細
        </TitleLabel>
      </Box>
      <Box marginTop="24px" marginLeft="16px" marginRight="16px">
        <Panel sx={{ borderRadius: '12px' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="12px"
            marginRight="16px"
            marginLeft="16px">
            <TitleLabel fontSize="15px">手續費</TitleLabel>
            <TitleLabel fontSize="15px" color="#6C757D">
              ${order.invoiceInfo.price}
            </TitleLabel>
          </Box>
          <Divider
            sx={{
              margin: '16px',
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginRight="16px"
            marginLeft="16px"
            marginBottom="16px">
            <TitleLabel fontSize="15px">總金額</TitleLabel>
            <TitleLabel fontSize="17px" color="#000000" fontWeight="bold">
              ${order.invoiceInfo.price}
            </TitleLabel>
          </Box>
        </Panel>
      </Box>
      <Box marginRight="16px" marginLeft="16px" marginTop="24px">
        <SubTitleLabel>
          注意事項：
          <br />
          根據財政部「電子發票實施作業要點」，OP加密資產存摺將為您的發票檔案儲存於您的訂單資料中，且將您的電子發票檔於訂單完成48小時內上傳到政府的「
          <span style={{ color: '#FF8F08' }}>電子發票整合服務平台</span>
          」，若需查詢發票請於48小時後再查詢，謝謝。
        </SubTitleLabel>
      </Box>
    </Box>
  );
}
