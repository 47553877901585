import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Box, Stack, Divider, Snackbar } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import { TitleLabel, SubTitleLabel, Label } from '../../components/Label';
import { useCountdown } from '../../hooks/useCountdown';
import moment from 'moment/moment';
import Barcode from 'react-barcode';
import { OrderAPI } from '../../apis/OrderAPI';
import BottomPanel from '../../components/BottomPanel';
import StyledButton from '../../components/StyledButton';
import Dialog from '../../components/Dialog';
import Panel from '../../components/Panel';

const sxContainer = {
  p: `${navigationBarHeight}px 16px 0`,
};

const paymentTitles = {
  bank: '請於時間內將指定金額匯入指定銀行',
  store: '請於時間內至台灣大哥大門市繳款',
};

const Navigation = ({ backNavigation }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (backNavigation) {
      navigate(backNavigation);
    } else {
      navigate(-1);
    }
  };

  return <NavigationBar title="付款資訊" leftButtonHandler={handleBack} />;
};

const PaymentTitle = ({ payment }) => {
  return (
    <Box align="center" mt="24px">
      <TitleLabel fontSize="18px">{paymentTitles[payment]}</TitleLabel>
    </Box>
  );
};

const PaymentCountdown = ({ settledExpireTime, id }) => {
  const { duration } = useCountdown(settledExpireTime);
  const navigate = useNavigate();

  async function updateOrder() {
    const order = await OrderAPI.getOrder(id);
    if (order.status !== 'open') {
      navigate('/tradeDone', { state: { order } });
    }
  }
  useEffect(() => {
    updateOrder();
  }, []);

  useEffect(() => {
    const interval = setInterval(updateOrder, 10000);
    return () => clearInterval(interval);
  }, [id, navigate, updateOrder]);

  return (
    <Box align="center">
      <TitleLabel fontSize="32px">
        {String(duration.minutes()).padStart(2, '0')}:
        {String(duration.seconds()).padStart(2, '0')}
      </TitleLabel>
    </Box>
  );
};

const PaymentDesc = ({ settledExpireTime }) => {
  const formatTime = (time) => moment.unix(time).format('YYYY/MM/DD HH:mm');
  return (
    <Box>
      <SubTitleLabel>
        此訂單將被保留至
        {formatTime(settledExpireTime)}
        ，請於期限內付款，若幣價波動過大，OP加密資產存摺有權取消此筆訂單。
      </SubTitleLabel>
    </Box>
  );
};

const OrderInfo = ({ productId, size, totalPrice, fund }) => {
  const orderInfos = [
    { title: '購買幣種', content: productId.split('-')[0] },
    {
      title: '購買數量',
      content: (
        <NumberFormat
          value={size}
          displayType={'text'}
          thousandSeparator={true}
          decimalScale={size < 100 ? 9 : 2}
        />
      ),
    },
    {
      title: '購買單價',
      content: (
        <>
          {' '}
          NT$&nbsp;
          <NumberFormat
            value={totalPrice}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={totalPrice < 100 ? 2 : 0}
            fixedDecimalScale={true}
          />
        </>
      ),
    },
    {
      title: '購買總金額',
      content: (
        <>
          {' '}
          NT$&nbsp;
          <NumberFormat
            value={fund}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={fund < 100 ? 2 : 0}
            fixedDecimalScale={true}
          />
        </>
      ),
    },
  ];

  const info = orderInfos.map((row, index) => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="8px 0"
          key={row.title}>
          <Label fontSize="15px">{row.title}</Label>
          <Label fontSize="15px">{row.content}</Label>
        </Box>
        {index === 2 && (
          <Box p="8px 0">
            <Divider />
          </Box>
        )}
      </>
    );
  });
  return (
    <Panel>
      <Box p="16px">{info}</Box>
    </Panel>
  );
};

const StorePaymentInfo = ({ paymentInfo }) => {
  const { barcode1, barcode2 } = paymentInfo;

  return (
    <Box marginBottom="50px">
      <TitleLabel fontSize="18px" sx={{ padding: '16px 0' }}>
        請向櫃檯人員出示付款條碼
      </TitleLabel>

      <Panel>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p="32px 0 14px 0">
          <Barcode height="65" width="3" background="#FFF" value={barcode1} />
          <Barcode height="65" width="3" background="#FFF" value={barcode2} />
        </Box>
      </Panel>
    </Box>
  );
};

const BankPaymentInfo = ({ paymentInfo }) => {
  const { bankCode, bankName, accountNumber } = paymentInfo;
  const bankPaymentInfos = [
    { title: '銀行代碼', content: `${bankCode} ${bankName}` },
    { title: '匯款帳號', content: accountNumber },
  ];

  const bankInfo = (
    <Stack p="24px 16px" spacing={3}>
      {bankPaymentInfos.map((row) => {
        return (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Label fontSize="15px">{row.title}</Label>
            <Label fontSize="15px">{row.content}</Label>
          </Box>
        );
      })}
    </Stack>
  );
  return (
    <Stack mt="24px" spacing={2}>
      <TitleLabel fontSize="18px">
        請於時間內將總金額之款項匯入指定帳號
      </TitleLabel>
      <Panel>{bankInfo}</Panel>
      <SubTitleLabel>
        請使用「綁定銀行帳號」於網路ATM、實體ATM、網路銀行將款項轉入以上帳號。
      </SubTitleLabel>
    </Stack>
  );
};

const BottomButton = ({ buttonName, onClick }) => {
  return (
    <BottomPanel>
      <StyledButton
        variant="contained"
        fullWidth
        disableElevation
        onClick={onClick}>
        {buttonName}
      </StyledButton>
    </BottomPanel>
  );
};

const CancelOrderPanel = ({ id }) => {
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const handleCancelOrder = async (id) => {
    const order = await OrderAPI.updateOrder(id);
    if (order) {
      navigate('/tradeDone', {
        state: { order, nextNavigation: '/assetHistory/ALL' },
      });
    }
  };
  return (
    <>
      <BottomButton buttonName="取消訂單" onClick={() => setShowAlert(true)} />
      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title="取消訂單"
        message="請問您是否要取消此筆訂單？"
        actionLabel="是"
        secondaryActionLabel="否"
        actionHandler={() => handleCancelOrder(id)}
        secondaryActionHandler={() => setShowAlert(false)}
      />
    </>
  );
};

const CopyBankAccountPanel = ({ paymentInfo }) => {
  const [openCopy, setOpenCopy] = useState(false);
  const handleCopyBankAccount = () => {
    navigator.clipboard.writeText(paymentInfo?.accountNumber);
    setOpenCopy(true);
  };
  return (
    <>
      <BottomButton buttonName="複製匯款帳號" onClick={handleCopyBankAccount} />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openCopy}
        autoHideDuration={3000}
        onClose={() => setOpenCopy(false)}
        message="匯款帳號已複製"
        key={'bottomCenter'}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: 'center',
          },
        }}
      />
    </>
  );
};

export default function OrderPaymentPage() {
  const { state } = useLocation();
  const {
    order: {
      size,
      productId,
      totalPrice,
      fund,
      payment,
      id,
      paymentInfo,
      settledExpireTime,
    },
    backNavigation,
    cancelOrder,
  } = state;

  return (
    <Box sx={sxContainer}>
      <Navigation backNavigation={backNavigation} />
      <Stack mb="32px" spacing={2} direction={'column'}>
        <PaymentTitle payment={payment} />
        <PaymentCountdown settledExpireTime={settledExpireTime} id={id} />
        <PaymentDesc settledExpireTime={settledExpireTime} />
      </Stack>
      <OrderInfo
        size={size}
        productId={productId}
        totalPrice={totalPrice}
        fund={fund}
      />
      {payment === 'bank' && (
        <>
          <BankPaymentInfo paymentInfo={paymentInfo} />
          <CopyBankAccountPanel paymentInfo={paymentInfo} />
        </>
      )}
      {payment === 'store' && <StorePaymentInfo paymentInfo={paymentInfo} />}
      {cancelOrder && <CancelOrderPanel id={id} />}
    </Box>
  );
}
