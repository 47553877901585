import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import { Box, List, ListItem, ListItemText, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/system';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserAPI } from '../../apis/UserAPI';
import Dialog from '../../components/Dialog';
import { SubTitleLabel, TitleLabel } from '../../components/Label';
import { navigationBarHeight } from '../../components/Layout';
import AccountInfoCell from '../../components/member/AccountInfoCell';
import NavigationBar from '../../components/NavigationBar';
import Panel from '../../components/Panel';
import Separator from '../../components/Separator';
import StyledButton from '../../components/StyledButton';
import { UserContext } from '../../UserContext';
// import { countries, getDistrictCode, getDistricts } from "../../utils/Invoice";
import { useEffectOnce } from '../../utils/UseEffectOnce';
import useInvoice from '../../hooks/useInvoice';

const InputBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FAFAFA',
  width: '100%',
  height: '56px',
  borderRadius: '9px',
}));

const userBankAccountStatus = {
  undefined: '綁定',
  unverified: '綁定',
  verifing: '審核中',
  succeed: '更改帳號',
  failed: '重新綁定',
};

const userBankAccountTitle = {
  undefined: '帳號未綁定',
  unverified: '帳號未綁定',
  verifing: '帳號綁定審核中',
  succeed: '帳號綁定成功',
  failed: '帳號綁定失敗',
};

export default function ChangeAccountInfoPage() {
  const { user, setUser, setLoading } = useContext(UserContext);
  const [errors] = useState({});
  const [disableButton, setDisableButton] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showcityDialog, setShowCityDialog] = useState(false);
  const [showDistrictDialog, setShowDistrictDialog] = useState(false);
  const [billInfo, setBillInfo] = useState({});
  const [address, setAddress] = useState(null);
  const [bankName, setBankName] = useState('');
  const [bankBranchName, setBankBranchName] = useState('');
  const status = user?.bankAccount?.status;
  const bankCode = user?.bankAccount?.bankCode;
  const bankBranchCode = user?.bankAccount?.branchCode;
  const cityRef = useRef(null);
  const districtRef = useRef(null);
  const zipCodeRef = useRef(null);
  const addressRef = useRef(null);

  const navigate = useNavigate();
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);
  const alertActionLabel = useRef(null);
  const { countries, getDistrictCode, getDistricts } = useInvoice();

  const { state } = useLocation();
  const order = state?.order
  const from = state?.from

  useEffectOnce(() => {
    async function fetchBillInfoData() {
      const userData = await UserAPI.getBillInfo();
      cityRef.current = userData.billAddrCity;
      districtRef.current = userData.billAddrDistrict;
      addressRef.current = userData.billAddr;
      zipCodeRef.current = userData.billAddrZipCode;
      setAddress(userData.billAddr);
    }

    fetchBillInfoData();
  }, [billInfo]);

  // useEffect(() => {
  //     if (status === "succeed") {
  //         const getBankBranchNames = async () => {
  //             setLoading(true);
  //             const _bankName = await getBankName(bankCode);
  //             const _bankBranchName = await getBankBranchName(
  //                 bankCode,
  //                 bankBranchCode
  //             );
  //             setBankName(_bankName);
  //             setBankBranchName(_bankBranchName);
  //             setLoading(false);
  //         };

  //         getBankBranchNames();
  //     }
  // }, [bankCode, bankBranchCode, setLoading, status]);

  const handleBack = () => {
    if (from === "tradeDone") {
      navigate("/tradeDone", { state: { order } });
    } else {
      navigate(-1);
    }
  };

  const sxContainer = {
    pt: `${navigationBarHeight}px`,
  };

  const sxTextField = {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'black',
    },
    // '& .MuiInputBase-input::placeholder': {
    //   color: 'black',
    //   opacity: 1,
    // },
  };

  const txtInputProps = {
    disableUnderline: true,
    style: {
      fontSize: '15px',
    },
  };

  async function handleUpdateBillInfo() {
    let needUdate = false;
    let alert = null;
    let payload = {};
    if (billInfo.billAddrCity !== cityRef.current) {
      payload['bill_addr_city'] = cityRef.current;
      needUdate = true;
    }

    if (billInfo.billAddrDistrict !== districtRef.current) {
      payload['bill_addr_zip_code'] = zipCodeRef.current;
      payload['bill_addr_district'] = districtRef.current;
      needUdate = true;
    }

    if (billInfo.billAddr !== addressRef.current) {
      payload['bill_addr'] = addressRef.current;
      needUdate = true;
    }

    if (!needUdate) {
      return;
    }

    if (alert) {
      alertTitle.current = '通訊地址更新失敗';
      alertMessage.current = alert;
      alertActionLabel.current = '確定';
      setShowAlert(true);
    } else {
      const userData = await UserAPI.updateBillInfo(payload);
      if (userData !== null) {
        handleBack();
      } else {
        alertTitle.current = '通訊地址更新失敗';
        alertMessage.current = '請確認資料是否正確';
        alertActionLabel.current = '確定';
        setShowAlert(true);
      }
    }
  }

  const navigation = () => {
    return <NavigationBar title="基本資料" leftButtonHandler={handleBack} />;
  };

  const isEmpty = (str) => {
    return str !== null && str.length !== 0;
  };

  const checkButtonDisable = () => {
    if (
      isEmpty(cityRef.current) &&
      isEmpty(districtRef.current) &&
      isEmpty(zipCodeRef.current) &&
      isEmpty(addressRef.current)
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const handleAlertAction = () => {
    setShowAlert(false);
  };

  const handleAddrInput = (value) => {
    addressRef.current = value;
    setAddress(value);
    checkButtonDisable();
  };

  const handleCityClick = (item) => {
    if (cityRef.current !== item) {
      cityRef.current = item;
      getDistricts(cityRef.current);
      districtRef.current = '';
      addressRef.current = null;
      setAddress(null);
    }
    setShowCityDialog(false);
    checkButtonDisable();
  };

  const handleDistrictClick = (item) => {
    districtRef.current = item;
    zipCodeRef.current = getDistrictCode(cityRef.current, item);
    setShowDistrictDialog(false);
    checkButtonDisable();
  };

  const handleBankClick = () => {
    if (!user?.bankAccount) {
      navigate('/bankAccount');
    }
  };

  const handleBankButtonStyle = (type) => {
    if (type === 'border') {
      return status === 'verifing' ? 1 : 0;
    } else if (type === 'background') {
      return status === 'verifing' ? '#FFFFFF' : '#FF8F08';
    } else {
      return status === 'verifing' ? '#000000' : '#FFFFFF';
    }
  };

  var infoItems = [
    {
      textLeft: '姓名',
      textRight: user?.chineseName,
      handler: null,
    },
    {
      textLeft: '身分證號',
      textRight: user?.identityNumber,
      handler: null,
    },
    {
      textLeft: '手機門號',
      textRight: user?.phoneNumber,
      handler: null,
    },
    {
      textLeft: '電子信箱',
      textRight: user?.email,
      handler: null,
    },
  ];

  const infoMenu = () => {
    return (
      <>
        <Box margin="16px">
          <Box marginBottom="16px">
            <TitleLabel fontSize="18px">個人資料</TitleLabel>
          </Box>
          <Panel sx={{ borderRadius: '8px' }}>
            <Box marginLeft="16px" marginRight="16px">
              {infoItems.map((item, index) => (
                <Box key={index}>
                  <AccountInfoCell item={item} />
                  {index < infoItems.length - 1 && <Separator />}
                </Box>
              ))}
            </Box>
          </Panel>
        </Box>
      </>
    );
  };

  const addressInfo = () => {
    return (
      <>
        <Box margin="16px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="16px">
            <TitleLabel fontSize="18px" fontWeight="bold">
              通訊地址
            </TitleLabel>
            <SubTitleLabel fontSize="15px">發票預設寄送地址</SubTitleLabel>
          </Box>
          <Panel>
            <Box margin="16px">
              <Box display="flex" sx={{ marginTop: '8px' }}>
                <InputBox
                  display="flex"
                  alignItems="center"
                  sx={{
                    padding: '16px',
                    border: 1,
                    borderColor: '#C1C1C1',
                    backgroundColor: '#FFFFFF',
                    marginRight: '5px',
                  }}
                  onClick={() => setShowCityDialog(true)}>
                  <TextField
                    variant="standard"
                    InputProps={txtInputProps}
                    hiddenLabel
                    autoComplete="off"
                    fullWidth
                    inputProps={{
                      min: 0,
                      style: {
                        textAlign: 'left',
                      },
                    }}
                    placeholder="請選擇縣市"
                    sx={sxTextField}
                    defaultValue={billInfo.billAddrCity}
                    value={cityRef.current}
                    disabled
                  />
                  <ArrowDropDownIcon />
                </InputBox>
                <InputBox
                  display="flex"
                  alignItems="center"
                  sx={{
                    padding: '16px',
                    border: 1,
                    borderColor: '#C1C1C1',
                    backgroundColor: '#FFFFFF',
                    marginLeft: '5px',
                  }}
                  onClick={
                    cityRef.current !== null
                      ? () => setShowDistrictDialog(true)
                      : null
                  }>
                  <TextField
                    variant="standard"
                    InputProps={txtInputProps}
                    hiddenLabel
                    autoComplete="off"
                    fullWidth
                    inputProps={{
                      min: 0,
                      style: {
                        textAlign: 'left',
                      },
                    }}
                    placeholder="選擇鄉鎮市區"
                    sx={sxTextField}
                    defaultValue={billInfo.billAddrDistrict}
                    value={districtRef.current}
                    disabled
                  />
                  <ArrowDropDownIcon />
                </InputBox>
              </Box>
              <InputBox
                display="flex"
                alignItems="center"
                sx={{
                  padding: '16px',
                  marginTop: '8px',
                  border: 1,
                  borderColor: '#C1C1C1',
                }}>
                <TextField
                  variant="standard"
                  InputProps={txtInputProps}
                  hiddenLabel
                  autoComplete="off"
                  fullWidth
                  inputProps={{
                    min: 0,
                    style: {
                      textAlign: 'left',
                    },
                  }}
                  // placeholder={addressRef.current}
                  value={address}
                  sx={sxTextField}
                  onChange={(e) => {
                    handleAddrInput(e.target.value);
                  }}
                />
              </InputBox>
              <Box marginTop="16px">
                <StyledButton
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={handleUpdateBillInfo}
                  disabled={disableButton}>
                  確定修改
                </StyledButton>
              </Box>
            </Box>
          </Panel>
        </Box>
      </>
    );
  };

  const bankInfo = () => {
    return (
      <>
        <Box margin="16px">
          <Box display="flex" marginBottom="16px">
            <TitleLabel fontSize="18px" fontWeight="bold">
              銀行帳號
            </TitleLabel>
          </Box>
          <Panel>
            <Box margin="16px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="16px">
                <TitleLabel fontSize="18px" fontWeight="bold">
                  {userBankAccountTitle[status]}
                </TitleLabel>
                <StyledButton
                  variant="contained"
                  style={{
                    backgroundColor: handleBankButtonStyle('background'),

                    color: handleBankButtonStyle('color'),
                  }}
                  disableElevation
                  sx={{
                    height: '48px',
                    border: handleBankButtonStyle('border'),
                  }}
                  onClick={handleBankClick}>
                  {userBankAccountStatus[status]}
                </StyledButton>
              </Box>

              {status === 'succeed' && (
                <>
                  <Separator />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="16px"
                    marginBottom="8px">
                    <TitleLabel fontSize="18px" fontWeight="bold">
                      {bankName}
                    </TitleLabel>
                    <TitleLabel fontSize="15px" fontWeight="bold">
                      已綁定
                    </TitleLabel>
                  </Box>
                  <SubTitleLabel fontSize="13px" marginBottom="24px">
                    {bankBranchName}
                  </SubTitleLabel>
                  <TitleLabel
                    fontSize="24px"
                    fontWeight="bold"
                    marginBottom="16px">
                    {user?.bankAccount?.accountNumber}
                  </TitleLabel>
                </>
              )}

              <Separator />
              <TitleLabel marginTop="16px" fontSize="15px" fontWeight="regular">
                ．用戶僅能用綁定的銀行帳號進行入金和提領的操作
                <br />
                ．用戶同一時間僅能綁定一個銀行帳號
              </TitleLabel>
            </Box>
          </Panel>
        </Box>
      </>
    );
  };

  const invoiceDialog = (
    title,
    dataList,
    showDialog,
    setShowDialog,
    ref,
    handle,
  ) => {
    return (
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title={title}>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
          }}>
          {dataList.map((item, index, data) => {
            if (index + 1 === data.length) {
              return (
                <React.Fragment>
                  <Box display="flex" alignItems="center" marginRight="15px">
                    <ListItem
                      sx={{ height: '56px' }}
                      onClick={() => handle(item)}>
                      <ListItemText
                        primary={
                          <SubTitleLabel
                            color="#000000"
                            fontSize="15px"
                            fontWeight="bold">
                            {item}
                          </SubTitleLabel>
                        }
                      />
                    </ListItem>
                    {ref.current === item && <CheckIcon />}
                  </Box>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <Box display="flex" alignItems="center" marginRight="15px">
                    <ListItem
                      sx={{
                        height: '56px',
                      }}
                      onClick={() => handle(item)}>
                      <ListItemText
                        primary={
                          <SubTitleLabel
                            color="#000000"
                            fontSize="15px"
                            fontWeight="bold">
                            {item}
                          </SubTitleLabel>
                        }
                      />
                    </ListItem>
                    {ref.current === item && <CheckIcon />}
                  </Box>
                  <Divider variant="middle" component="li" />
                </React.Fragment>
              );
            }
          })}
        </List>
      </Dialog>
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {navigation() /* navigation bar */}
        {infoMenu()}
        {addressInfo()}
        {/* {bankInfo()} */}
      </Box>
      {invoiceDialog(
        '請選擇縣市',
        countries,
        showcityDialog,
        setShowCityDialog,
        cityRef,
        handleCityClick,
      )}
      {invoiceDialog(
        '請選擇鄉鎮市區',
        getDistricts(cityRef.current),
        showDistrictDialog,
        setShowDistrictDialog,
        districtRef,
        handleDistrictClick,
      )}
      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel={alertActionLabel.current}
        actionHandler={handleAlertAction}
      />
    </>
  );
}
